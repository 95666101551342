import * as React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { links } from '../config/links';
import { Cell } from '../components/Cell';
import { Layout } from '../components/Layout';
import { Logo } from '../components/Logo';
import { Row } from '../components/Row';
import { SEO } from '../components/SEO';

const LearnMore = () => {
  return (
    <Layout>
      <Row className="bg-gradient-to-b from-gray-800 to-gray-900 text-white">
        <Cell>
          <div className="h-screen">
            <div className="grid md:grid-cols-2 py-8 md:py-12">
              <a href="/" className="mx-auto md:mx-0 mb-6 md:mb-0">
                {/* @ts-ignore */}
                <Logo width={250} height={50} />
              </a>
              <div className="md:ml-auto text-center md:text-left">
                <OutboundLink
                  href={links.demo}
                  className="cursor-pointer inline-flex items-center px-6 py-3 text-base font-medium rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-150 ease-in-out border border-primary hover:border-white text-gray-900 bg-primary hover:bg-white"
                >
                  Schedule Demo
                </OutboundLink>
              </div>
            </div>
            <div
              style={{
                position: 'relative',
                paddingBottom: '56.25%',
                height: '0',
              }}
            >
              <iframe
                className="rounded shadow-2xl"
                src="https://www.loom.com/embed/c1ec3483d15f486292f5ea4031bafd88"
                allowFullScreen
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                }}
              ></iframe>
            </div>
          </div>
        </Cell>
      </Row>
    </Layout>
  );
};

export function Head() {
  return (
    <SEO>
      <title id="title">EASE // Learn More</title>
    </SEO>
  );
}

export default LearnMore;
